import { useEffect, useMemo, useRef, useState } from "react";
import "./App.css";
import debounce from "lodash/debounce";
import { motion } from "framer-motion";
const isDev =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";
const BASE_URL = isDev ? "http://127.0.0.1:8000" : "https://ln.weifan.xyz";

function App() {
  const [isDark, setIsDark] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [dirs, setDirs] = useState([]);
  const [movies, setMovies] = useState([]);
  const [selectedDir, setSelectedDir] = useState(null);
  const [saved, setSaved] = useState([]);
  const [viewing, setViewing] = useState(-1);
  const [initial, setInitial] = useState(true);
  const [username, setUsername] = useState("");
  const ref = useRef();
  const DIRECTOR_LIST_ANIMATION = {
    hidden: { opacity: 0, y: -10 },
    show: { opacity: 1, y: 0, transition: { type: "spring" } },
  };
  const MOVIE_LIST_ANIMATION = {
    hidden: { opacity: 0, y: -10 },
    show: { opacity: 1, y: 0, transition: { type: "spring" } },
  };
  const toggleDark = () => {
    setIsDark(!isDark);
    storeLocally(!isDark, "isDark");
    if (!isDark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };
  const onChange = () => {
    setSelectedDir(null);
    fetch(BASE_URL + "/api/director/search?q=" + searchText)
      .then((response) => response.json())
      .then((json) => setDirs(json.data));
  };

  useEffect(() => {
    ref.current = onChange;
  }, [onChange]);

  useEffect(() => {
    const isDark = JSON.parse(fetchLocally("isDark"));
    setIsDark(isDark);
    document.documentElement.classList.add(isDark ? "dark" : "light");
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
      const existingUser = JSON.parse(fetchLocally("existingUser"));
      if (existingUser) {
        setInitial(false);
        fetchDB(existingUser);
        window.location.pathname = "/" + existingUser;
      }
      return;
    } else {
      setInitial(false);
      const path = window.location.pathname.split("/")[1];
      fetchDB(path);
    }
  }, [window.location.pathname]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };
    return debounce(func, 500);
  }, []);

  const selectDir = (dir) => {
    setSearchText("");
    setSelectedDir(dir);
    setMovies([]);
    fetch(BASE_URL + "/api/director/movies?q=" + dir.id)
      .then((response) => response.json())
      .then((json) =>
        setMovies(
          json.data
            .sort((a, b) => new Date(b.release_date) - new Date(a.release_date))
            .map((m) => ({ ...m, checked: false }))
        )
      );
  };

  const selectMovie = (director, movie, allMovies) => {
    console.log(director, movie);
    let newMovies = [...allMovies].map((m) => {
      if (m.id === movie.id) {
        m.checked = !m.checked;
      }
      return m;
    });
    let newSaved = [...saved];
    if (movie.checked) {
      // check if director is already in saved list
      let dir = newSaved.find((d) => d.director.id === director.id);
      if (dir) {
        dir.movies = newMovies;
      } else {
        newSaved.push({
          director: director,
          movies: newMovies,
        });
      }
    }
    setSaved(newSaved);
    storeLocally(newSaved, "saved");
    storeDB(newSaved);
    setMovies(newMovies);
  };

  const storeDB = (saved) => {
    const user = window.location.pathname.split("/")[1];
    fetch(BASE_URL + "/api/director/store", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: JSON.stringify(saved), user }),
    }).then((response) => response.json());
  };

  const fetchDB = (user) => {
    fetch(BASE_URL + "/api/director/fetch/" + user)
      .then((response) => response.json())
      .then((json) => {
        if (json.content) {
          const data = JSON.parse(json.content);
          setSaved(data);
        } else {
          // const saved = fetchLocally("saved");
          // if (saved) {
          //   setSaved(JSON.parse(saved));
          // }
        }
      });
  };

  const storeLocally = (saved, title) => {
    localStorage.setItem(title, JSON.stringify(saved));
  };

  const fetchLocally = (title) => {
    return localStorage.getItem(title);
  };

  const backToList = () => {
    setSelectedDir(null);
    setDirs([]);
    setMovies([]);
  };

  const createUser = () => {
    if (username.length > 0) {
      window.location.pathname = "/" + username;
      storeLocally(username, "existingUser");
    }
  };
  return (
    <div className="App">
      <div className="header">
        <div onClick={backToList}>directors</div>
        <div onClick={toggleDark}>{isDark ? "light" : "dark"} </div>
      </div>
      {initial ? (
        <>
          <h1>Welcome to Dirs</h1>
          <h3>Create a username to get started</h3>
          <input
            type="text"
            placeholder="username"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                createUser();
              }
            }}
          />
          <div
            className="submit-button"
            onClick={() => {
              createUser();
            }}
          >
            Submit
          </div>
        </>
      ) : (
        <>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search director"
              onChange={(e) => {
                debouncedCallback();
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>
          {saved.length > 0 && !selectedDir && !dirs?.length ? (
            <motion.div
              initial="hidden"
              animate="show"
              viewport={{ once: true }}
              variants={{
                hidden: {},
                show: {
                  transition: {
                    staggerChildren: 0.15,
                  },
                },
              }}
            >
              {saved.map((s, sIndex) => (
                <motion.div
                  variants={DIRECTOR_LIST_ANIMATION}
                  className="list-container"
                >
                  <h2
                    className="director-name"
                    onClick={() => setViewing(viewing === sIndex ? -1 : sIndex)}
                  >
                    {s.director?.name}
                  </h2>
                  <div className="watched-text">
                    {s.movies.filter((m) => m.checked).length}/{s.movies.length}{" "}
                    watched
                  </div>
                  <motion.div
                    animate={viewing === sIndex ? "show" : "hidden"}
                    initial="hidden"
                    variants={{
                      hidden: {
                        transition: {
                          delay: 0.1,
                        },
                      },
                      show: {
                        transition: {
                          staggerChildren: 0.01,
                        },
                      },
                    }}
                  >
                    {viewing === sIndex &&
                      s.movies.map((movie) => (
                        <motion.div
                          key={movie.id}
                          className="movie-list"
                          onClick={() =>
                            selectMovie(s.director, movie, s.movies)
                          }
                          variants={MOVIE_LIST_ANIMATION}
                        >
                          <input type="checkbox" checked={movie.checked} />
                          <div>
                            {movie.title} ({movie.release_date.substring(0, 4)})
                          </div>
                        </motion.div>
                      ))}
                  </motion.div>
                </motion.div>
              ))}
            </motion.div>
          ) : null}
          {selectedDir ? null : (
            <motion.div
              animate={dirs ? "show" : "hidden"}
              initial="hidden"
              viewport={{ once: true }}
              variants={{
                hidden: {},
                show: {
                  transition: {
                    staggerChildren: 0.01,
                  },
                },
              }}
              className="list-container"
            >
              {dirs?.map((dir) => (
                <motion.div
                  variants={MOVIE_LIST_ANIMATION}
                  className="director-list"
                  onClick={() => selectDir(dir)}
                >
                  {dir.name}
                </motion.div>
              ))}
            </motion.div>
          )}
          {selectedDir ? (
            <div className="list-container">
              <div className="movie-list" onClick={backToList}>
                {"< back to list"}
              </div>
              <h2>{selectedDir.name}'s Movies</h2>
              <motion.div
                animate={selectedDir ? "show" : "hidden"}
                initial="hidden"
                viewport={{ once: true }}
                variants={{
                  hidden: {},
                  show: {
                    transition: {
                      staggerChildren: 0.01,
                    },
                  },
                }}
              >
                {movies?.map((movie) => (
                  <motion.div
                    className="movie-list"
                    onClick={() => selectMovie(selectedDir, movie, movies)}
                    variants={MOVIE_LIST_ANIMATION}
                  >
                    <input type="checkbox" checked={movie.checked} />
                    <div>
                      {movie.title} ({movie.release_date.substring(0, 4)})
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
export default App;
